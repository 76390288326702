import type { PosthogImplementedEvent } from "@asap/shared/src/types/posthog";

/**
 * EXAMPLE USAGE:
 * const posthog = usePosthog();
 * posthog.trackEvent("event", { property: "value" });
 *
 * ADD EVENTS IN @asap/shared/src/types/posthog.ts
 *
 * IMPORTANT:
 * For testing in development, add the Posthog Api Key to .env in @asap/api
 * And comment the early return on the composable
 */
export const usePosthog = () => {
  // This should normally run only on production once this is setup and test, so we avoid spamming backend
  const { isDevelopment, isProduction } = useFeatureFlag();
  if (!isProduction) return;

  const { authUser, teamMember, workspaceId, mainWorkspace, userRoles, currentWorkspace, workspaces, businessUnit } =
    useUserMe();
  const { invoke } = useApi();
  const route = useRoute();
  const currentUrl = route.fullPath;

  const trackEvent = async (event: PosthogImplementedEvent, properties?: Record<string, unknown>) => {
    if (
      authUser.value?.id &&
      teamMember.value?.id &&
      teamMember.value?.management_level &&
      mainWorkspace.value?.id &&
      workspaceId.value &&
      currentWorkspace.value?.id &&
      workspaces.value.length &&
      userRoles.value &&
      authUser.value?.first_name &&
      authUser.value?.last_name &&
      authUser.value?.created_at
    ) {
      const answer = await invoke({
        path: "analytics/track-event",
        method: "POST",
        body: {
          event,
          properties,
          current_url: currentUrl,
          identity: {
            auth_user_id: authUser.value?.id,
            team_member_id: teamMember.value?.id,
            management_level: teamMember.value?.management_level,
            main_workspace_id: mainWorkspace.value?.id,
            current_workspace_id: currentWorkspace.value?.id,
            workspace_ids: workspaces.value.map((workspace) => workspace.id),
            user_roles: userRoles.value,
            email: authUser.value?.email,
            first_name: authUser.value?.first_name,
            last_name: authUser.value?.last_name,
            created_at: authUser.value?.created_at,
            business_unit_id: businessUnit.value?.id,
            business_unit_name: businessUnit.value?.name,
          },
        },
      });
      // If you're debugging, this can be useful to see the event captured
      if (answer.success && isDevelopment) {
        console.log("Event captured: ", { event, properties, currentUrl });
      }
    }
  };

  const isFeatureFlagEnabled = async (name: string): Promise<boolean> => {
    const answer = await invoke({
      path: "feature-flag/posthog",
      method: "GET",
      query: { name },
    });
    if (answer.success) {
      return answer.data.enabled;
    }
    return false;
  };

  return { trackEvent, isFeatureFlagEnabled };
};
